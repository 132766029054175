const colors = {
    light: {
        bg: '#FFF',
        bgDark: '#F9F9F9',
        text: '#030303',
        border: '#CCC',
        shadow: '#EEEEEE',
        inpt: { bg: '#FFF' },
        btn: { bg: '#F8F8F8', border: '#E5E5E5', shadow: '#E5E5E5' },
    },
    dark: {
        bg: '#202020',
        bgDark: '#181818',
        text: '#FFF',
        border: '#303030',
        shadow: '#00000000',
        inpt: { bg: '#121212' },
        btn: { bg: '#323232', border: '#303030', shadow: '#00000000' },
    }
}

export default colors