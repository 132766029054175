import React from 'react'
import styled from "styled-components"
import { ThemeContext } from '../App'

import sun from '../assets/images/sun.svg'
import moon from '../assets/images/moon.svg'

const ThemeSwitchStyled = styled.img`
    height: 24px;
    cursor: pointer;
`

export default function ThemeSwitch() {
    const { isDark, toggleTheme } = React.useContext(ThemeContext)

    return <ThemeSwitchStyled
        src={isDark ? moon : sun}
        onClick={toggleTheme} />
}