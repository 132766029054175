import React, { createContext } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import VideoPlayer from './pages/VideoPlayer';
import Home from './pages/Home';
import * as Persistence from './service/persistence'

import { ROUTE_HOME, ROUTE_WATCH } from './routes'

export const ThemeContext = createContext()

function App() {
  const [isDark, setIsDark] = React.useState(() => Persistence.isDarkMode())

  const toggleDarkMode = (isDark) => {
    setIsDark(isDark)

    Persistence.saveIsDarkMode(isDark)
  }

  const theme = {
    isDark: isDark,
    toggleTheme: () => toggleDarkMode(!isDark)
  }
  return < BrowserRouter >
    <ThemeContext.Provider value={theme}>
      <Routes>
        <Route path={ROUTE_HOME} element={<Home />} />
        <Route path={ROUTE_WATCH} element={<VideoPlayer />} />
      </Routes>
    </ThemeContext.Provider >
  </BrowserRouter >
}

export default App;
