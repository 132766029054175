import React from 'react'
import { Link } from "react-router-dom";
import styled from "styled-components"

import { ThemeContext } from '../App'
import ThemeSwitch from '../components/ThemeSwitch';

import colorTheme from '../theme/colors'
import logo from '../assets/images/logo.png'

const HeaderStyled = styled.nav`
    background-color: ${({ colors }) => colors.bg};
    display: flex;
    flex:1;
    height: 56px;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;
`
const Row = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const HomeLink = styled(Link)`
    margin-right: 10px;
`
const Logo = styled.img`
    height: 40px;
`

export default function Header({ left, middle, right }) {
    const { isDark } = React.useContext(ThemeContext)

    const colors = isDark ? colorTheme.dark : colorTheme.light

    const Right = () => {
        if (right) return right

        return <ThemeSwitch />
    }

    return <HeaderStyled colors={colors}>

        <Row>
            <HomeLink to="/"> <Logo src={logo} alt="logo" /> </HomeLink>
            {left}
        </Row>

        {middle}

        <Right />
    </HeaderStyled>
}
