// TODO: save in ENV
// ...
// Linked to abdel.alim.zerkani
const api_key = 'AIzaSyAKKnaKU6mol_9f8gI3DNzpXEfqN9pT8As'

/**
 * 
 * @param {string} id A comma-separated list of the YouTube playlist ID(s) 
 */
export const playlistInfo = (id) => {
    const url = `https://www.googleapis.com/youtube/v3/playlists?part=snippet&id=${id}&key=${api_key}&maxResults=50`
    return fetch(url)
        .then(response => response.json())
        .catch(error => {
            console.log(error)
            return {}
        })
}