import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import YouTube from 'react-youtube';
import { ThemeContext } from '../App'
import * as Persistence from '../service/persistence'
import Header from '../components/Header';

import theme from '../theme/colors'

const Container = styled.div`
`
const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 20px;
  background-color: ${({ colors }) => colors.bgDark};
`

const TextLink = styled(Link)`
  color: ${({ colors }) => colors.text};
`

export default function VideoPlayer() {
  const themeContext = React.useContext(ThemeContext)

  const playerRef = React.useRef()
  const playingRef = React.useRef(false)

  let [searchParams] = useSearchParams()

  const playlistId = searchParams.get('playlist')
  const { index, start } = getProgress()

  const height = 495
  const width = height * 16 / 9
  const opts = {
    height: height,
    width: width,
    playerVars: {
      autoplay: 1,
      listType: 'playlist',
      list: playlistId,
      index: index + 1, // It seems that the index here is not zero based
      start: Math.floor(start),
    },
  };

  //const _player = () => playerRef.current.getInternalPlayer()

  const onReady = (e) => saveProgressRegularly(e.target)

  const onPlay = (e) => {
    playingRef.current = true
    saveProgress(e.target)
  }

  const onPause = (e) => {
    playingRef.current = false
    saveProgress(e.target)
  }

  const onEnd = (e) => playNextVideo(e.target)

  const saveProgressRegularly = (player) => {
    // TODO: release timer
    setInterval(() => { playingRef.current && saveProgress(player) }, 30000);
  }

  const playNextVideo = (player) => {
    if (player.getPlaylistIndex() < player.getPlaylist().length - 1)
      player.nextVideo()
  }

  function saveProgress(player) {
    const data = {
      playlist: playlistId,
      index: player.getPlaylistIndex(),
      start: player.getCurrentTime()
    }

    Persistence.savePlaylistProgress(data)
  }

  function getProgress() {
    const progress = Persistence.getPlaylistProgress(playlistId)
    return progress
  }

  const colors = themeContext.isDark ? theme.dark : theme.light
  return (
    <Container>
      < Header left={<TextLink to="/" colors={colors}>Back</TextLink>} />


      <VideoContainer colors={colors}>
        <YouTube ref={playerRef} opts={opts} onReady={onReady} onPlay={onPlay} onPause={onPause} onEnd={onEnd} />
      </VideoContainer>
    </Container>
  )
}
