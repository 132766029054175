import React from 'react'
import { useNavigate, } from "react-router-dom"
import styled from 'styled-components'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROUTE_WATCH, PARAM_PLAYLIST } from '../routes'
import { ThemeContext } from '../App'

import colorTheme from '../theme/colors'

const Form = styled.form`
    display: flex;
    flex: 0 1 560px;
    margin: 0 auto;
    background-color: ${({ colors }) => colors.bg};
`
const Row = styled.div`
    display: flex;
    flex:1;
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex: 0 1 560px;
    flex-direction: column;
`
const Input = styled.input`
    width: 100%;
    height:40px;
    background-color: ${({ colors }) => colors.inpt.bg};
    border: ${({ colors }) => `1px solid ${colors.border}`};
	border-right: none;
	border-radius: 2px 0 0 2px;
	box-shadow: ${({ colors }) => `inset 0 1px 2px ${colors.shadow}`};
	color: ${({ colors }) => `${colors.text}`};
	padding: 2px 6px;
    outline: none;
    &:focus {
        border: 1px solid #1c62b9;
    }
`
const Button = styled.button`
	color: ${({ colors }) => colors.text};
	border: ${({ colors }) => `1px solid ${colors.border}`};
	background-color: ${({ colors }) => colors.btn.bg};
	border-radius: 0 2px 2px 0;
	cursor: pointer;
	height: 40px;
	width: 64px;
	margin: 0;
`

export default function PlaylistForm() {
    const themeContext = React.useContext(ThemeContext)

    const [playlist, setPlaylist] = React.useState('')

    const colors = themeContext.isDark ? colorTheme.dark : colorTheme.light

    let navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();

        if (isValidForm()) {
            const playlistId = getPlaylistId(playlist)
            navigate(`${ROUTE_WATCH}?${PARAM_PLAYLIST}=${playlistId}`)
        }
    }

    const isValidForm = () => {
        if (!playlist) {
            toast.error("Please fill in the playlist url")
            return false
        }

        const id = getPlaylistId(playlist)
        if (id === null) {
            toast.error("Invalid playlist url")
            return false
        }

        return true
    }

    const getPlaylistId = (url) => {
        try {
            let params = (new URL(url)).searchParams
            return params.get('list')
        } catch (error) {
            return null
        }
    }

    return (
        <Form onSubmit={onSubmit} colors={colors}>
            <Row>
                <Column>
                    <Input
                        colors={colors}
                        type="text"
                        placeholder="Playlist url"
                        value={playlist}
                        onChange={(e) => setPlaylist(e.target.value)}
                    />
                </Column>
                <Button colors={colors}>Watch</Button>
            </Row>
            <ToastContainer theme={themeContext.isDark ? "dark" : "light"} />
        </Form>
    )
}
