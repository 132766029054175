import React from 'react'

import List from '../pages/List';
import Header from '../components/Header';
import PlaylistForm from '../components/PlaylistForm';

export default function Home() {
    return <>
        < Header middle={<PlaylistForm />} />
        <List />
    </>
}
